.card{
    width: auto;
    margin: 10px 25px;
   @media (min-width: 768px){
    width: 690px;
    margin: 20px auto;
   }
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 20px;
    z-index: 50;
    font-size: 14px;
    .landing-card__title{
        font-size: 16px;
    } 
    .button-hover:hover{
        background: $color-orange;
        transition: 0.3s;
    }
    .card-number{
        position: absolute;
        left: -15px;
        top: 31px;
        z-index: 10;
        width: 0;
        height: 0;
        border-right: 23px solid transparent;
        border-top: 12px solid transparent;
        border-left: 12px solid #6B7280;
        border-bottom: 13px solid #6B7280;
        transform: rotate(45deg);
    }
    .tring{
        position: absolute;
        left: -20px;
        top: 39px;
        z-index: 30;
        width: 45px;
        height: 30px;
    }
    .card-title{
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 1px;
    }
    .card-footer{
        font-size: 18px;
        font-weight: 400;
        @media (min-width: 768px){
            font-size: 20px;
        }
        .card-footer__checkbox{
            width: 20px;
            height: 20px;
            box-sizing: border-box;
            border-radius: 4px;
        }
        .top-span{
            top: -2px;
        }   
    }
}
.opacity{
    opacity: 0.5;
}
.linetime{
    position: relative;
    &:after{
        content: '';
        display: block;
        height: 130%;
        width: 3px;
        background: $color-orange;
        position: absolute;
        left: 45%;
    }
}
.time-line_card{
    ul{
        li:last-child{
            .linetime{
                position: relative;
                &:after{
                    display: none;
                }
            }
        }
    }
}
.circle-red{
    width: 32px;
    height: 32px
}

.upload-assets{
    .card-title{
        @media (min-width: 768px){
            padding-left: 40px;   
        }
    }
}
.cm-card {
    .list-item-check:before {
        content: "✓";
        position: absolute;
        left: 18px;
    }
    .link-mobile{
        font-size: 12px;
        @media (min-width: 768px){
            font-size: 18px;
        }
    }
}