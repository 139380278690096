.nav{
   margin-top: 20px;
   @media (min-width: 1030px){
    width: 333px;
    height: 500px;
    top: 450px;
    left: 100px;
   }
   .nav-list{
      flex-direction: row;
      @media (min-width: 1030px){
         flex-direction: column;
      }
      .nav-item{
         position: relative;
         margin: 18px 0px;
        .nav-item_link {
         font-size: 14px;
         padding: 0px 10px; 
         @media (min-width: 768px){
               padding: 0 20px;
         }
           &.active{
              color: $color-black;
              opacity: 1 !important;
            &:before {
              content: "";
              display: inline-block;
              width:5px;
              height: 40px;
              background: $color-orange;
              position: absolute;
              z-index: 20;
              left: 0px;
              top: -7px;
            }
           }
           &.inactive{
            opacity: 0.1;
               &:after {
                 content: "";
                 display: inline-block;
                 width:5px;
                 height: 40px;
                 background: $color-black !important;
                 position: absolute;
                 z-index: 10;
                 left: 0px;
                 top: -7px;
               }
           }
        } 
      }
   }
}