.sub-title{
    font-size: 24px;
    margin: auto;
    padding: 0px 20px;
    @media (min-width: 768px){
        width: 500px;
    }
}

.fill-card{
    height: 413px;
}
.bg-image{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.iframe{
    margin: 20px auto 20px auto;
}