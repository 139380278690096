@font-face {
  font-family: 'Big Caslon';
  src: local("Big Caslon"),
    url(../../components/fonts/Big-Caslon-Regular.woff2) format('woff2'),
    url(../../components/fonts/Big-Caslon-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,500&display=swap');