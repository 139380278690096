//Background
.bg-black{
    background: $color-black;
}
.bg-salmon{
    background: $color-salmone ;
}
.bg-white{
    background: $color-white;
}

.bg-orange{
    background: $color-orange;
}
//Font
.fcolor-black{
    color: $color-black;
}
.fcolor-white{
    color: $color-white;
}
.fcolor-orange{
    color: $color-orange;
}
.f-bigCaslon-normal{
    font-family: $big-caslon;
}
.f-bigCaslon-bold{
    font-family: $big-caslon;
    font-weight: bold;
}

.f-monserrat-normal{
    font-family: $monserrat;
    font-weight: normal;
}
.f-monserrat-bold{
    font-family: $monserrat;
    font-weight: bold;
}