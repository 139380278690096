@import "components/variables";
@import "components/fonts";
@import "components/colors";

//Sections
@import "components/sections/header";
@import "components/sections/welcome";

//Shared
@import "components/shared/nav";
@import "components/shared/card";

//Pages
@import "components/pages/linetime";
@import "components/pages/upload";

body{
    background: #F7E8E2;
}
.list{
    list-style: auto;
}
.list-desc{
    list-style: disc;
}
.bottom{
    margin-bottom: 250px;
}
@media (max-width: 1025px ){
    .todoLine{
        flex-direction: column;
    }
}