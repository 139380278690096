.header{
    height: 365px;
    @media (min-width: 768px){
        height: 448px;   
    }
    background-image: url(../../../images/bg-hero-allure.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}
.square{
    &:before{
        content: "";
        display: block;
        background: $color-salmone;
        width: 144.04px;
        height: 144.04px;
        @media (min-width: 768px){
            width: 206.79px;
            height: 206.79px;
        }
        margin: auto;
        border-radius: 0px 0px 19px 19px;
        background-image: url(../../../images/allure-store.svg);
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }
}