.welcome-container{
    @media (min-width: 768px){
        width: 690px;
    }
    .welcome-title{
        font-size: 30px;
    }
    .welcome-text{
        font-size: 14px;
    }
}